import unExitsApi from '@/api/unExits'

const state = {
  unExits: [],
  unExitDetail: null,
  searchPlate: '',
  selectedUnExit: null,
}

const getters = {
  filteredUnExits: state => state.unExits.filter(t => {
    if (!state.searchPlate) return true
    if (!t.plate) return false
    return t.plate.includes(state.searchPlate)
  })
}

const mutations = {
  SET_UN_EXITS: (state, unExits) => state.unExits = unExits,
  SET_UN_EXIT_DETAIL: (state, detail) => state.unExitDetail = detail,
  SET_SEARCH_PLATE: (state, plate) => state.searchPlate = plate,
  SELECT_UN_EXIT: (state, unExit) => state.selectedUnExit = unExit,
  ADD_UN_EXIT: (state, unExit) => state.unExits.unshift(unExit),
}

const actions = {
  fetchUnExits({ commit }) {
    unExitsApi.getList().then(r => {
      commit('SET_UN_EXITS', r.data);
    })
  },
  openUnExitDetail({ commit }, id) {
    unExitsApi.get(id).then(r => {
      commit('SET_UN_EXIT_DETAIL', r.data)
    })
  },
  closeUnExitDetail({ commit }) {
    commit('SET_UN_EXIT_DETAIL', null)
  },
  filterUnExits({ commit }, plate) {
    commit('SET_SEARCH_PLATE', plate)
  },
  deleteUnExits({ dispatch }, ids) {
    unExitsApi.deleteRange(ids).then(() => {
      dispatch('showAlert', { message: `${ids.length} выездов успешно удалено` }, { root: true })
      dispatch('fetchUnExits')
    })
  },
  selectUnExit: ({ commit }, unExit) => commit('SELECT_UN_EXIT', unExit),
  matchUnExit: ({ dispatch, state }, { ticketId, plate }) => {
    unExitsApi.match(state.selectedUnExit.id, ticketId, plate).then(() => {
      dispatch('showAlert', { message: 'Соответствие билета и выезда успешно выполнено' }, { root: true })
      dispatch('fetchUnExits')
      dispatch('tickets/fetchTickets', null, { root: true })
    })
  },
  addUnExit: ({ commit }, unExit) => commit('ADD_UN_EXIT', unExit),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}