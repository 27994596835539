export default {
  namespaced: false,

  state: {
    isLoading: false,
    level: 0
  },

  mutations: {
    SET_IS_LOADING(state, { isLoading, force }) {
      if (!isLoading && force) {
        state.level = 0
        state.isLoading = false
        return
      }

      state.level = isLoading ? state.level + 1 : state.level - 1
      state.isLoading = state.level > 0
    }
  },

  actions: {
    startLoading({ commit }) {
      commit('SET_IS_LOADING', { isLoading: true })
    },
    stopLoading({ commit }, force) {
      commit('SET_IS_LOADING', { isLoading: false, force: force })
    }
  }
}