import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const LoginApp = () => import(/* webpackChunkName: "login" */ '@/components/LoginApp.vue')

const BusinessApp = () => import(/* webpackChunkName: "business" */ '@/components/owner/BusinessApp.vue')
const Passes = () => import(/* webpackChunkName: "business" */ '@/components/owner/passes/Passes.vue')
const PassesAdd = () => import(/* webpackChunkName: "business" */ '@/components/owner/passes/PassesAdd.vue')
const Statements = () => import(/* webpackChunkName: "business" */ '@/components/owner/statements/Statements.vue')
const Loyalty = () => import(/* webpackChunkName: "business" */ '@/components/owner/loyalty/Loyalty.vue')

const OperatorApp = () => import(/* webpackChunkName: "operator" */ '@/components/operator/OperatorApp.vue')
const Dashboard = () => import(/* webpackChunkName: "operator" */ '@/components/operator/Dashboard.vue')
const Mismatches = () => import(/* webpackChunkName: "operator" */ '@/components/operator/mismatches/Mismatches.vue')

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'app',
      beforeEnter: (_to, _from, next) => {
        const token = localStorage.getItem('token');
        if (token) {
          const role = parseJwt(token).role;
          switch (role) {
            case 'Operator': router.push({ path: '/operator' }); break;
            case 'ParkingOwner': router.push({ path: '/owner' }); break;
          }
        } else {
          next({ name: 'login' });
        }
      }
    },
    { name: 'login', path: '/login', component: LoginApp },
    {
      path: '/owner',
      component: BusinessApp,
      meta: { requiresAuth: true, roles: ['ParkingOwner'] },
      children: [
        { name: 'passes', path: 'passes', component: Passes, },
        { name: 'passAdd', path: 'passes/add', component: PassesAdd, },
        { name: 'passEdit', path: 'passes/edit/:id', props: true, component: PassesAdd },
        { name: 'statements', path: 'statements', component: Statements, },
        { name: 'loyalty', path: 'loyalty', component: Loyalty, },
        { path: '', redirect: { name: 'passes' } }
      ]
    },
    {
      path: '/operator',
      component: OperatorApp,
      meta: { requiresAuth: true, roles: ['Operator'] },
      children: [
        { name: 'dashboard', path: 'dashboard', component: Dashboard, },
        { name: 'mismatches', path: 'mismatches', component: Mismatches, },
        { path: '', redirect: { name: 'dashboard' } }
      ]
    }
  ]
})

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    const token = localStorage.getItem('token');
    if (token) {
      const role = parseJwt(token).role;
      const hasAccess = to.matched.some(record => record.meta.roles && record.meta.roles.some(r => r === role));
      if (hasAccess) {
        next();
      } else {
        next({ name: 'login' });
      }
    } else {
      next({ name: 'login' });
    }
  } else {
    next();
  }
})

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export default router