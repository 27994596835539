import merchantsApi from '@/api/merchants'

const state = {
  merchants: [],
}

const mutations = {
  SET_MERCHANTS: (state, merchants) => state.merchants = merchants,
}

const actions = {
  fetchMerchants: ({ commit }) => merchantsApi.getList().then(r => commit('SET_MERCHANTS', r.data)),
  addMerchant: ({ dispatch }, merchant) => merchantsApi.add(merchant).then(() => dispatch('fetchMerchants')),
  selectMerchant: ({ dispatch }, merchant) => dispatch('qrCodes/fetchQrCodes', merchant.id, { root: true }),
  deleteMerchant: ({ dispatch }, id) => merchantsApi.delete(id).then(() => dispatch('fetchMerchants')),
}

export default { namespaced: true, state, mutations, actions }