import passesApi from '@/api/passes'

const state = {
  passes: [],
}

const mutations = {
  SET_PASSES: (state, passes) => state.passes = passes,
}

const actions = {
  fetchPasses: ({ commit }) => passesApi.getList().then(r => commit('SET_PASSES', r.data)),
  addPass: ({ dispatch }, pass) => passesApi.add(pass).then(() => dispatch('fetchPasses')),
  editPass: ({ dispatch }, pass) => passesApi.edit(pass).then(() => dispatch('fetchPasses')),
  deletePass: ({ dispatch }, pass) => passesApi.delete(pass).then(() => dispatch('fetchPasses')),
  downloadCsv: () => passesApi.downloadCsv().then(r => {
    const url = window.URL.createObjectURL(new Blob([r.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'пропуски.csv');
    document.body.appendChild(link);
    link.click();
  }),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}