import ticketsApi from '@/api/tickets'

const state = {
  tickets: [],
  ticketDetail: null,
  searchPlate: '',
  selectedTicket: null,
}

const getters = {
  filteredTickets: state => state.tickets.filter(t => {
    if (!state.searchPlate) return true
    if (!t.plate) return false
    return t.plate.includes(state.searchPlate)
  })
}

const mutations = {
  SET_TICKETS(state, tickets) {
    state.tickets = tickets
  },
  SET_TICKET_DETAIL(state, detail) {
    state.ticketDetail = detail
  },
  SELECT_TICKET(state, ticket) {
    state.selectedTicket = ticket
  },
  SET_SEARCH_PLATE(state, plate) {
    state.searchPlate = plate
  },
  ADD_TICKET(state, ticket) {
    state.tickets.unshift(ticket)
  },
  UPDATE_TICKET(state, ticket) {
    state.tickets.forEach((element, i) => {
      if (element.id === ticket.id) {
        state.tickets[i] = ticket
      }
    });
  },
  REMOVE_TICKET(state, ticket) {
    state.tickets = state.tickets.filter(t => t.id !== ticket.id)
  },
}

const actions = {
  fetchTickets({ commit }) {
    ticketsApi.getList().then(r => commit('SET_TICKETS', r.data))
  },
  openTicketDetail({ commit }, id) {
    ticketsApi.get(id).then(r => {
      commit('SET_TICKET_DETAIL', r.data)
    })
  },
  closeTicketDetail({ commit }) {
    commit('SET_TICKET_DETAIL', null)
  },
  changePlate({ dispatch }, payload) {
    ticketsApi.changePlate(payload.id, payload.plate).then(() => {
      dispatch('showAlert', { message: "ГРНЗ успешно обновлен" }, { root: true })
      dispatch('fetchTickets')
    })
  },
  filterTickets({ commit }, plate) {
    commit('SET_SEARCH_PLATE', plate)
  },
  addTicket({ commit }, ticket) {
    commit('ADD_TICKET', ticket)
  },
  removeTicket({ commit }, ticket) {
    commit('REMOVE_TICKET', ticket)
  },
  deleteTickets({ dispatch }, ids) {
    return ticketsApi.deleteRange(ids).then(() => {
      dispatch('showAlert', { message: `${ids.length} билетов успешно удалено` }, { root: true })
      dispatch('fetchTickets')
    })
  },
  selectTicket: ({ commit }, ticket) => commit('SELECT_TICKET', ticket),
  createTicket: ({ dispatch }, ticket) => {
    return ticketsApi.create(ticket).then(() => {
      dispatch('showAlert', { message: `Билет с ГРНЗ ${ticket.plate} успешно создан` }, { root: true })
      dispatch('fetchTickets')
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}