import moment from 'moment'
import statementsApi from '@/api/statements'

const state = {
  statements: [],
  totalCount: 0,
  totalAmount: 0,

  criteria: {
    dateFrom: moment().startOf('day').toDate(),
    dateTo: moment().toDate(),
    plate: '',
    paymentTypes: [1, 2, 3, 4, 5],
    minMinutes: 0,
    maxMinutes: 0,
    page: 1,
    count: 10,
  },
}

const mutations = {
  SET_STATEMENTS(state, payload) {
    state.statements = payload.statements
    state.totalCount = payload.totalCount
    state.totalAmount = payload.totalAmount
  },
  SET_FILTER(state, payload) {
    state.criteria.dateFrom = payload.dateFrom
    state.criteria.dateTo = payload.dateTo
    state.criteria.plate = payload.plate
    state.criteria.paymentTypes = payload.paymentTypes
    state.criteria.minMinutes = payload.minMinutes
    state.criteria.maxMinutes = payload.maxMinutes
  },
  SET_PAGINATION(state, payload) {
    state.criteria.page = payload.page
    state.criteria.count = payload.count
  },
}

const actions = {
  fetchStatements({ state, commit }) {
    statementsApi.getList(state.criteria).then(r => {
      commit('SET_STATEMENTS', r.data)
    })
  },
  filter({ commit, dispatch }, payload) {
    commit('SET_FILTER', payload)
    dispatch('fetchStatements')
  },
  paginate({ commit, dispatch }, payload) {
    commit('SET_PAGINATION', payload)
    dispatch('fetchStatements')
  },
  downloadCsv({ state }) {
    statementsApi.getCsv(state.criteria).then(r => {
      const url = window.URL.createObjectURL(new Blob([r.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'выписка.csv');
      document.body.appendChild(link);
      link.click();
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}