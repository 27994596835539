import exitsApi from '@/api/exits'

const state = {
  exits: [],
}

const getters = {
  getByLane: state => lane => state.exits.find(e => e.lane === lane),
}

const mutations = {
  SET_EXITS(state, exits) {
    state.exits = exits
  },
  ADD_EXIT(state, exit) {
    state.exits = state.exits.filter(e => e.lane !== exit.lane)
    state.exits.push(exit)
  },
  REMOVE_EXIT(state, { id }) {
    const exit = state.exits.find(e => e.id === id)
    if (exit) {
      state.exits = state.exits.filter(e => e.id !== exit.id)
      state.exits.push(Object.assign({}, exit, { isRemoved: true }))
    }
  },
}

const actions = {
  fetchExits({ commit }) {
    exitsApi.getList().then(r => {
      commit('SET_EXITS', r.data);
    })
  },
  addExit({ commit }, exit) {
    commit('ADD_EXIT', exit)
  },
  removeExit({ commit }, exit) {
    commit('REMOVE_EXIT', exit)
  },
  changePlate({ dispatch }, { id, plate }) {
    exitsApi.changePlate(id, plate).then(() => {
      dispatch('showAlert', { message: "ГРНЗ успешно обновлен" }, { root: true })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}