import axios from 'axios'
import authApi from '@/api/auth.js'

export default {
  state: {
    token: ''
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('token', token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },
    CLEAR_TOKEN() {
      localStorage.removeItem('token')
      location.reload()
    },
  },

  actions: {
    login({ commit, dispatch }, credentials) {
      authApi.login(credentials.username, credentials.password)
        .then(response => {
          commit('SET_TOKEN', response.data.access_token)
          credentials.success()
        }).catch(() =>
          dispatch('showAlert', { message: "Не верно указаны логин и пароль" }, { root: true })
        )
    },
    logout({ commit }) {
      commit('CLEAR_TOKEN')
    },
  }
}