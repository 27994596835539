import entriesApi from '@/api/entries'

const state = {
  entries: [],
}

const getters = {
  getByLane: state => lane => state.entries.find(e => e.lane === lane),
}

const mutations = {
  SET_ENTRIES(state, entries) {
    state.entries = entries
  },
  ADD_ENTRY(state, entry) {
    state.entries = state.entries.filter(e => e.lane !== entry.lane)
    state.entries.push(entry)
  },
  REMOVE_ENTRY(state, { id }) {
    const entry = state.entries.find(e => e.id === id)
    if (entry) {
      state.entries = state.entries.filter(e => e.id !== entry.id)
      state.entries.push(Object.assign({}, entry, { isRemoved: true }))
    }
  },
}

const actions = {
  fetchEntries({ commit }) {
    entriesApi.getList().then(r => {
      commit('SET_ENTRIES', r.data);
    })
  },
  addEntry({ commit }, entry) {
    commit('ADD_ENTRY', entry)
  },
  removeEntry({ commit }, entry) {
    commit('REMOVE_ENTRY', entry)
  },
  changePlate({ dispatch }, { id, plate }) {
    entriesApi.changePlate(id, plate).then(() => {
      dispatch('showAlert', { message: "ГРНЗ успешно обновлен" }, { root: true })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}