import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth.js'
import snackbar from '@/store/modules/snackbar.js'
import progressBar from '@/store/modules/progressBar.js'
import passes from '@/store/modules/passes.js'
import statements from '@/store/modules/statements.js'
import tickets from '@/store/modules/tickets.js'
import entries from '@/store/modules/entries.js'
import exits from '@/store/modules/exits.js'
import unExits from '@/store/modules/unExits.js'
import merchants from '@/store/modules/merchants.js'
import qrCodes from '@/store/modules/qrCodes.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    progressBar,
    passes,
    statements,
    tickets,
    entries,
    exits,
    unExits,
    merchants,
    qrCodes,
  }
})